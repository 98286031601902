@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,900&family=Source+Code+Pro:wght@300&display=swap");

$black: #2a2a24;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  font-size: 14px;
  font-family: "lato";
  
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
  color: black;
  -webkit-font-smoothing: antialiased;
  background: #f1f4f8;
}

// header

header {
  position: absolute;
  height: 108px;
  width: 100%;
  padding: 24px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $black;
  .episode {
    span {
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 0.04rem;
    }
  }
}
logo {
  width: 110px;
  display: flex;
}
logo img {
  width: 70%;
  align-items: center;
}

.card{
  border-radius: 2%;
  background-color: #2a2a24;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 4%;
  
}



.btn {
  background-color: black;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 15px;
  width: 100%;
  height: 50px;
  margin-top: 0px;
  cursor: pointer;
}

.greenDot {
  /* Ellipse 3 */

  position: absolute;
  width: 15px;
  height: 15px;
  margin-left: 0.5%;
  margin-top: 0.4%;
  background: #11da00;
  border-radius: 255px;
}

.grid-container {
  width: 50%;
  display: grid;
  grid-gap:5px;
  grid-template-columns: auto auto ;
  padding: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 1%;    
}

.grid-item {
  color: white;
  background-color: #2a2a24;
  border: none;    
  padding: 20px;
  font-size: 30px;
  text-align: center;
  border-radius: 2%;
  width: 150px;
  height: 150px;
  cursor: pointer;
}

@media (max-width: 300px) {
  .grid-container {
      grid-template-columns: repeat(1, 1fr);
  }
}