html,
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  color: #606063;
  overflow: hidden;
  font-family: "News Cycle", sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

canvas {
  position: absolute;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}


@font-face {
  font-family: "lato";
  src: local("lato"),
   url("./lato.bold.ttf") format("truetype");
  font-weight: bold;
  }