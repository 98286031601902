@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@1,900&family=Source+Code+Pro:wght@300&display=swap);
html,
body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  color: #606063;
  overflow: hidden;
  font-family: "News Cycle", sans-serif;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

canvas {
  position: absolute;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}


@font-face {
  font-family: "lato";
  src: local("lato"),
   url(/static/media/lato.bold.eb953203.ttf) format("truetype");
  font-weight: bold;
  }
*{box-sizing:border-box}html,body,#root{width:100%;height:100%;margin:0;padding:0;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;overflow:hidden;font-size:14px;font-family:"lato"}#root{overflow:auto}body{position:fixed;overflow:hidden;overscroll-behavior-y:none;font-family:-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;color:#000;-webkit-font-smoothing:antialiased;background:#f1f4f8}header{position:absolute;height:108px;width:100%;padding:24px 48px;display:flex;align-items:center;justify-content:space-between;color:#2a2a24}header .episode span{font-size:1rem;font-weight:700;letter-spacing:.04rem}logo{width:110px;display:flex}logo img{width:70%;align-items:center}.card{border-radius:2%;background-color:#2a2a24;display:flex;flex-direction:column;color:#fff;padding:4%}.btn{background-color:#000;border:none;color:#fff;text-align:center;text-decoration:none;display:inline-block;font-size:16px;border-radius:15px;width:100%;height:50px;margin-top:0px;cursor:pointer}.greenDot{position:absolute;width:15px;height:15px;margin-left:.5%;margin-top:.4%;background:#11da00;border-radius:255px}.grid-container{width:50%;display:grid;grid-gap:5px;grid-template-columns:auto auto;padding:10px;align-items:center;justify-content:center;margin-top:1%}.grid-item{color:#fff;background-color:#2a2a24;border:none;padding:20px;font-size:30px;text-align:center;border-radius:2%;width:150px;height:150px;cursor:pointer}@media(max-width: 300px){.grid-container{grid-template-columns:repeat(1, 1fr)}}
